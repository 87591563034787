// 系统参数配置

// PC接口请求地址
let url = {
  //服务器主站运行启用
  production: "https://www.hk1686.com",
  //production: "http://169.254.186.99:8084",
  // development: "http://169.254.186.11:8084",
  development: "http://169.254.186.99:8084",
  //development: "https://www.hk1686.com",
};
// 客服页面地址
let url2 = {
  production: "https://www.hk1686.cn",
  development: "http://169.254.186.99:8888",
  // development: "http://169.254.186.11:8888",
  //development: "http://192.168.31.157:8888",
  //development: "http://169.254.186.16:8888"
};
// 客服接口请求地址
let url3 = {
  production: "https://www.hk1686.cn",
  //production: "http://169.254.186.99:8008",
  // development: "http://169.254.186.11:8008",
  // development: "https://www.hk1686.cn",
  development: "http://169.254.186.99:8008",
};

let baseURL;
let env = process.env.NODE_ENV;
switch (env) {
  case "production":
    baseURL = url[env];
    break;
  case "development":
    baseURL = url[env];
    break;
  default:
    "";
}
let customerUrl;

switch (env) {
  case "production":
    customerUrl = url2[env];
    break;
  case "development":
    customerUrl = url2[env];
    break;
  default:
    "";
}

let customerRequestUrl;

switch (env) {
  case "production":
    customerRequestUrl = url3[env];
    break;
  case "development":
    customerRequestUrl = url3[env];
    break;
  default:
    "";
}

export default {
  url: url, // 接口地址
  baseURL: baseURL, // PC地址
  customerUrl: customerUrl, // 客服地址
  customerRequestUrl: customerRequestUrl, //客服接口请求地址
  headers: {
    ContentType: "multipart/form-data",
    // ContentType: 'application/x-www-form-urlencoded'
  },

  timeout: 10000, // 请求超时
  version: "1.0.0", // 应用版本
};
