import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9d00f8e8 = () => interopDefault(import('..\\pages\\allgame.vue' /* webpackChunkName: "pages/allgame" */))
const _7d281792 = () => interopDefault(import('..\\pages\\auctionPage\\index.vue' /* webpackChunkName: "pages/auctionPage/index" */))
const _d422fe6c = () => interopDefault(import('..\\pages\\black\\index.vue' /* webpackChunkName: "pages/black/index" */))
const _5ff9dc16 = () => interopDefault(import('..\\pages\\center\\index.vue' /* webpackChunkName: "pages/center/index" */))
const _6dc1ea49 = () => interopDefault(import('..\\pages\\contact\\index.vue' /* webpackChunkName: "pages/contact/index" */))
const _7425a4f9 = () => interopDefault(import('..\\pages\\Conversations\\index.vue' /* webpackChunkName: "pages/Conversations/index" */))
const _d0e3e37c = () => interopDefault(import('..\\pages\\dealshow\\index.vue' /* webpackChunkName: "pages/dealshow/index" */))
const _bb298482 = () => interopDefault(import('..\\pages\\forgetPassword.vue' /* webpackChunkName: "pages/forgetPassword" */))
const _39e15a56 = () => interopDefault(import('..\\pages\\gj-wzry\\index.vue' /* webpackChunkName: "pages/gj-wzry/index" */))
const _774d3e05 = () => interopDefault(import('..\\pages\\jump.vue' /* webpackChunkName: "pages/jump" */))
const _539a948f = () => interopDefault(import('..\\pages\\kfyz\\index.vue' /* webpackChunkName: "pages/kfyz/index" */))
const _35bce425 = () => interopDefault(import('..\\pages\\log\\index.vue' /* webpackChunkName: "pages/log/index" */))
const _34aba1bc = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _30916a61 = () => interopDefault(import('..\\pages\\moreAnchor\\index.vue' /* webpackChunkName: "pages/moreAnchor/index" */))
const _6c6d7718 = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _15d3fe7a = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _422abc69 = () => interopDefault(import('..\\pages\\test.vue' /* webpackChunkName: "pages/test" */))
const _54c5bed3 = () => interopDefault(import('..\\pages\\wares\\index.vue' /* webpackChunkName: "pages/wares/index" */))
const _90dc8e3e = () => interopDefault(import('..\\pages\\waresNew\\index.vue' /* webpackChunkName: "pages/waresNew/index" */))
const _460c266c = () => interopDefault(import('..\\pages\\Bargbuy\\Babuy.vue' /* webpackChunkName: "pages/Bargbuy/Babuy" */))
const _e73902a0 = () => interopDefault(import('..\\pages\\Bargbuy\\Partialpayment.vue' /* webpackChunkName: "pages/Bargbuy/Partialpayment" */))
const _8766d096 = () => interopDefault(import('..\\pages\\black\\blackList.vue' /* webpackChunkName: "pages/black/blackList" */))
const _433bba0d = () => interopDefault(import('..\\pages\\black\\center.vue' /* webpackChunkName: "pages/black/center" */))
const _2441f070 = () => interopDefault(import('..\\pages\\black\\releaseBlack.vue' /* webpackChunkName: "pages/black/releaseBlack" */))
const _500f33d2 = () => interopDefault(import('..\\pages\\black\\zhgs.vue' /* webpackChunkName: "pages/black/zhgs" */))
const _0bcdab76 = () => interopDefault(import('..\\pages\\center\\buyers\\index.vue' /* webpackChunkName: "pages/center/buyers/index" */))
const _1f599904 = () => interopDefault(import('..\\pages\\center\\sell\\index.vue' /* webpackChunkName: "pages/center/sell/index" */))
const _4316cd42 = () => interopDefault(import('..\\pages\\dealshow\\selltime.vue' /* webpackChunkName: "pages/dealshow/selltime" */))
const _e8060222 = () => interopDefault(import('..\\pages\\fastGold\\fastGoldOrder.vue' /* webpackChunkName: "pages/fastGold/fastGoldOrder" */))
const _14b73d9b = () => interopDefault(import('..\\pages\\gj-wzry\\hpjyEvaluator.vue' /* webpackChunkName: "pages/gj-wzry/hpjyEvaluator" */))
const _7d7aa8aa = () => interopDefault(import('..\\pages\\specilPage\\createAccountInfo.vue' /* webpackChunkName: "pages/specilPage/createAccountInfo" */))
const _12f99c45 = () => interopDefault(import('..\\pages\\specilPage\\downloadApp.vue' /* webpackChunkName: "pages/specilPage/downloadApp" */))
const _4fb1cce4 = () => interopDefault(import('..\\pages\\specilPage\\hpjyEvaluator.vue' /* webpackChunkName: "pages/specilPage/hpjyEvaluator" */))
const _22f1236f = () => interopDefault(import('..\\pages\\center\\activity\\play.vue' /* webpackChunkName: "pages/center/activity/play" */))
const _886a6416 = () => interopDefault(import('..\\pages\\center\\assets\\assetsFinancing.vue' /* webpackChunkName: "pages/center/assets/assetsFinancing" */))
const _ec4e3850 = () => interopDefault(import('..\\pages\\center\\buyers\\BuyBargain.vue' /* webpackChunkName: "pages/center/buyers/BuyBargain" */))
const _6c10c4a4 = () => interopDefault(import('..\\pages\\center\\buyers\\buyBargainGoods.vue' /* webpackChunkName: "pages/center/buyers/buyBargainGoods" */))
const _3624ce51 = () => interopDefault(import('..\\pages\\center\\buyers\\buyComplaint.vue' /* webpackChunkName: "pages/center/buyers/buyComplaint" */))
const _372fed26 = () => interopDefault(import('..\\pages\\center\\buyers\\buyFastGold.vue' /* webpackChunkName: "pages/center/buyers/buyFastGold" */))
const _7a76ae97 = () => interopDefault(import('..\\pages\\center\\buyers\\buyFastGoldDetail.vue' /* webpackChunkName: "pages/center/buyers/buyFastGoldDetail" */))
const _288b1d3c = () => interopDefault(import('..\\pages\\center\\buyers\\buyGoodsOrder.vue' /* webpackChunkName: "pages/center/buyers/buyGoodsOrder" */))
const _296959ae = () => interopDefault(import('..\\pages\\center\\buyers\\buyGuarantee.vue' /* webpackChunkName: "pages/center/buyers/buyGuarantee" */))
const _4e4482f3 = () => interopDefault(import('..\\pages\\center\\buyers\\buyLikedGoods.vue' /* webpackChunkName: "pages/center/buyers/buyLikedGoods" */))
const _6a0c5536 = () => interopDefault(import('..\\pages\\center\\buyers\\buyOrderDetail.vue' /* webpackChunkName: "pages/center/buyers/buyOrderDetail" */))
const _773c3a42 = () => interopDefault(import('..\\pages\\center\\buyers\\buyOrderInfo.vue' /* webpackChunkName: "pages/center/buyers/buyOrderInfo" */))
const _5353e129 = () => interopDefault(import('..\\pages\\center\\buyers\\buySeckillOrder.vue' /* webpackChunkName: "pages/center/buyers/buySeckillOrder" */))
const _fb254576 = () => interopDefault(import('..\\pages\\center\\buyers\\lotteryRecords.vue' /* webpackChunkName: "pages/center/buyers/lotteryRecords" */))
const _6bb9be81 = () => interopDefault(import('..\\pages\\center\\buyers\\sellerOrderInfo.vue' /* webpackChunkName: "pages/center/buyers/sellerOrderInfo" */))
const _50142663 = () => interopDefault(import('..\\pages\\center\\extend\\extendMyExtension.vue' /* webpackChunkName: "pages/center/extend/extendMyExtension" */))
const _45b75eb4 = () => interopDefault(import('..\\pages\\center\\extend\\qrCode.vue' /* webpackChunkName: "pages/center/extend/qrCode" */))
const _6895499a = () => interopDefault(import('..\\pages\\center\\extend\\sellgooSn.vue' /* webpackChunkName: "pages/center/extend/sellgooSn" */))
const _29dfe886 = () => interopDefault(import('..\\pages\\center\\feedback\\complaintcustomerservice.vue' /* webpackChunkName: "pages/center/feedback/complaintcustomerservice" */))
const _668867d5 = () => interopDefault(import('..\\pages\\center\\feedback\\feedbackandsuggestion.vue' /* webpackChunkName: "pages/center/feedback/feedbackandsuggestion" */))
const _4cc888ab = () => interopDefault(import('..\\pages\\center\\feedback\\feedbackrecording.vue' /* webpackChunkName: "pages/center/feedback/feedbackrecording" */))
const _3acc09bc = () => interopDefault(import('..\\pages\\center\\log\\history.vue' /* webpackChunkName: "pages/center/log/history" */))
const _2234a289 = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\index.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/index" */))
const _7c8e5730 = () => interopDefault(import('..\\pages\\center\\myself\\buyIdentity.vue' /* webpackChunkName: "pages/center/myself/buyIdentity" */))
const _f9af0da8 = () => interopDefault(import('..\\pages\\center\\myself\\cashWithdrawal.vue' /* webpackChunkName: "pages/center/myself/cashWithdrawal" */))
const _e726e8d0 = () => interopDefault(import('..\\pages\\center\\myself\\changeEmail.vue' /* webpackChunkName: "pages/center/myself/changeEmail" */))
const _364e90df = () => interopDefault(import('..\\pages\\center\\myself\\changePayPassword.vue' /* webpackChunkName: "pages/center/myself/changePayPassword" */))
const _5e97772c = () => interopDefault(import('..\\pages\\center\\myself\\changePhone.vue' /* webpackChunkName: "pages/center/myself/changePhone" */))
const _2cb134d8 = () => interopDefault(import('..\\pages\\center\\myself\\depositWithdraw.vue' /* webpackChunkName: "pages/center/myself/depositWithdraw" */))
const _b2527e8c = () => interopDefault(import('..\\pages\\center\\myself\\identityAuthentication.vue' /* webpackChunkName: "pages/center/myself/identityAuthentication" */))
const _43bca3aa = () => interopDefault(import('..\\pages\\center\\myself\\myselfInfo.vue' /* webpackChunkName: "pages/center/myself/myselfInfo" */))
const _908aadea = () => interopDefault(import('..\\pages\\center\\myself\\recharge.vue' /* webpackChunkName: "pages/center/myself/recharge" */))
const _31a26592 = () => interopDefault(import('..\\pages\\center\\myself\\thirdPartyBinding.vue' /* webpackChunkName: "pages/center/myself/thirdPartyBinding" */))
const _2edb1cd8 = () => interopDefault(import('..\\pages\\center\\sell\\sellBargainGoods.vue' /* webpackChunkName: "pages/center/sell/sellBargainGoods" */))
const _c3622d64 = () => interopDefault(import('..\\pages\\center\\sell\\sellOrder.vue' /* webpackChunkName: "pages/center/sell/sellOrder" */))
const _3c26f7d8 = () => interopDefault(import('..\\pages\\center\\sell\\sellOutGoods.vue' /* webpackChunkName: "pages/center/sell/sellOutGoods" */))
const _07c71863 = () => interopDefault(import('..\\pages\\center\\sell\\Sellreceive.vue' /* webpackChunkName: "pages/center/sell/Sellreceive" */))
const _4f3574fa = () => interopDefault(import('..\\pages\\center\\sell\\sellSuccess.vue' /* webpackChunkName: "pages/center/sell/sellSuccess" */))
const _486a2015 = () => interopDefault(import('..\\pages\\center\\sell\\sellTab.vue' /* webpackChunkName: "pages/center/sell/sellTab" */))
const _7b498b78 = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\changePassword.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/changePassword" */))
const _3cbf2fd6 = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\changePhone.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/changePhone" */))
const _1c028c0b = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _05778ef2 = () => interopDefault(import('..\\pages\\black\\_sn.vue' /* webpackChunkName: "pages/black/_sn" */))
const _bcdc867c = () => interopDefault(import('..\\pages\\detail\\_sn.vue' /* webpackChunkName: "pages/detail/_sn" */))
const _f61f4d14 = () => interopDefault(import('..\\pages\\JDetail\\_sn.vue' /* webpackChunkName: "pages/JDetail/_sn" */))
const _6e382812 = () => interopDefault(import('..\\pages\\news\\_code.vue' /* webpackChunkName: "pages/news/_code" */))
const _2acaa9ba = () => interopDefault(import('..\\pages\\goods\\_sn\\order.vue' /* webpackChunkName: "pages/goods/_sn/order" */))
const _34a182bd = () => interopDefault(import('..\\pages\\goods\\_sn\\pay.vue' /* webpackChunkName: "pages/goods/_sn/pay" */))
const _40c7e618 = () => interopDefault(import('..\\pages\\goods\\_sn\\success.vue' /* webpackChunkName: "pages/goods/_sn/success" */))
const _36e15163 = () => interopDefault(import('..\\pages\\wares\\_sn\\order.vue' /* webpackChunkName: "pages/wares/_sn/order" */))
const _22dcb8fd = () => interopDefault(import('..\\pages\\wares\\_sn\\pay.vue' /* webpackChunkName: "pages/wares/_sn/pay" */))
const _d4040750 = () => interopDefault(import('..\\pages\\wares\\_sn\\success.vue' /* webpackChunkName: "pages/wares/_sn/success" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/allgame",
    component: _9d00f8e8,
    name: "allgame"
  }, {
    path: "/auctionPage",
    component: _7d281792,
    name: "auctionPage"
  }, {
    path: "/black",
    component: _d422fe6c,
    name: "black"
  }, {
    path: "/center",
    component: _5ff9dc16,
    name: "center"
  }, {
    path: "/contact",
    component: _6dc1ea49,
    name: "contact"
  }, {
    path: "/Conversations",
    component: _7425a4f9,
    name: "Conversations"
  }, {
    path: "/dealshow",
    component: _d0e3e37c,
    name: "dealshow"
  }, {
    path: "/forgetPassword",
    component: _bb298482,
    name: "forgetPassword"
  }, {
    path: "/gj-wzry",
    component: _39e15a56,
    name: "gj-wzry"
  }, {
    path: "/jump",
    component: _774d3e05,
    name: "jump"
  }, {
    path: "/kfyz",
    component: _539a948f,
    name: "kfyz"
  }, {
    path: "/log",
    component: _35bce425,
    name: "log"
  }, {
    path: "/login",
    component: _34aba1bc,
    name: "login"
  }, {
    path: "/moreAnchor",
    component: _30916a61,
    name: "moreAnchor"
  }, {
    path: "/news",
    component: _6c6d7718,
    name: "news"
  }, {
    path: "/register",
    component: _15d3fe7a,
    name: "register"
  }, {
    path: "/test",
    component: _422abc69,
    name: "test"
  }, {
    path: "/wares",
    component: _54c5bed3,
    name: "wares"
  }, {
    path: "/waresNew",
    component: _90dc8e3e,
    name: "waresNew"
  }, {
    path: "/Bargbuy/Babuy",
    component: _460c266c,
    name: "Bargbuy-Babuy"
  }, {
    path: "/Bargbuy/Partialpayment",
    component: _e73902a0,
    name: "Bargbuy-Partialpayment"
  }, {
    path: "/black/blackList",
    component: _8766d096,
    name: "black-blackList"
  }, {
    path: "/black/center",
    component: _433bba0d,
    name: "black-center"
  }, {
    path: "/black/releaseBlack",
    component: _2441f070,
    name: "black-releaseBlack"
  }, {
    path: "/black/zhgs",
    component: _500f33d2,
    name: "black-zhgs"
  }, {
    path: "/center/buyers",
    component: _0bcdab76,
    name: "center-buyers"
  }, {
    path: "/center/sell",
    component: _1f599904,
    name: "center-sell"
  }, {
    path: "/dealshow/selltime",
    component: _4316cd42,
    name: "dealshow-selltime"
  }, {
    path: "/fastGold/fastGoldOrder",
    component: _e8060222,
    name: "fastGold-fastGoldOrder"
  }, {
    path: "/gj-wzry/hpjyEvaluator",
    component: _14b73d9b,
    name: "gj-wzry-hpjyEvaluator"
  }, {
    path: "/specilPage/createAccountInfo",
    component: _7d7aa8aa,
    name: "specilPage-createAccountInfo"
  }, {
    path: "/specilPage/downloadApp",
    component: _12f99c45,
    name: "specilPage-downloadApp"
  }, {
    path: "/specilPage/hpjyEvaluator",
    component: _4fb1cce4,
    name: "specilPage-hpjyEvaluator"
  }, {
    path: "/center/activity/play",
    component: _22f1236f,
    name: "center-activity-play"
  }, {
    path: "/center/assets/assetsFinancing",
    component: _886a6416,
    name: "center-assets-assetsFinancing"
  }, {
    path: "/center/buyers/BuyBargain",
    component: _ec4e3850,
    name: "center-buyers-BuyBargain"
  }, {
    path: "/center/buyers/buyBargainGoods",
    component: _6c10c4a4,
    name: "center-buyers-buyBargainGoods"
  }, {
    path: "/center/buyers/buyComplaint",
    component: _3624ce51,
    name: "center-buyers-buyComplaint"
  }, {
    path: "/center/buyers/buyFastGold",
    component: _372fed26,
    name: "center-buyers-buyFastGold"
  }, {
    path: "/center/buyers/buyFastGoldDetail",
    component: _7a76ae97,
    name: "center-buyers-buyFastGoldDetail"
  }, {
    path: "/center/buyers/buyGoodsOrder",
    component: _288b1d3c,
    name: "center-buyers-buyGoodsOrder"
  }, {
    path: "/center/buyers/buyGuarantee",
    component: _296959ae,
    name: "center-buyers-buyGuarantee"
  }, {
    path: "/center/buyers/buyLikedGoods",
    component: _4e4482f3,
    name: "center-buyers-buyLikedGoods"
  }, {
    path: "/center/buyers/buyOrderDetail",
    component: _6a0c5536,
    name: "center-buyers-buyOrderDetail"
  }, {
    path: "/center/buyers/buyOrderInfo",
    component: _773c3a42,
    name: "center-buyers-buyOrderInfo"
  }, {
    path: "/center/buyers/buySeckillOrder",
    component: _5353e129,
    name: "center-buyers-buySeckillOrder"
  }, {
    path: "/center/buyers/lotteryRecords",
    component: _fb254576,
    name: "center-buyers-lotteryRecords"
  }, {
    path: "/center/buyers/sellerOrderInfo",
    component: _6bb9be81,
    name: "center-buyers-sellerOrderInfo"
  }, {
    path: "/center/extend/extendMyExtension",
    component: _50142663,
    name: "center-extend-extendMyExtension"
  }, {
    path: "/center/extend/qrCode",
    component: _45b75eb4,
    name: "center-extend-qrCode"
  }, {
    path: "/center/extend/sellgooSn",
    component: _6895499a,
    name: "center-extend-sellgooSn"
  }, {
    path: "/center/feedback/complaintcustomerservice",
    component: _29dfe886,
    name: "center-feedback-complaintcustomerservice"
  }, {
    path: "/center/feedback/feedbackandsuggestion",
    component: _668867d5,
    name: "center-feedback-feedbackandsuggestion"
  }, {
    path: "/center/feedback/feedbackrecording",
    component: _4cc888ab,
    name: "center-feedback-feedbackrecording"
  }, {
    path: "/center/log/history",
    component: _3acc09bc,
    name: "center-log-history"
  }, {
    path: "/center/myself/accountSecurity",
    component: _2234a289,
    name: "center-myself-accountSecurity"
  }, {
    path: "/center/myself/buyIdentity",
    component: _7c8e5730,
    name: "center-myself-buyIdentity"
  }, {
    path: "/center/myself/cashWithdrawal",
    component: _f9af0da8,
    name: "center-myself-cashWithdrawal"
  }, {
    path: "/center/myself/changeEmail",
    component: _e726e8d0,
    name: "center-myself-changeEmail"
  }, {
    path: "/center/myself/changePayPassword",
    component: _364e90df,
    name: "center-myself-changePayPassword"
  }, {
    path: "/center/myself/changePhone",
    component: _5e97772c,
    name: "center-myself-changePhone"
  }, {
    path: "/center/myself/depositWithdraw",
    component: _2cb134d8,
    name: "center-myself-depositWithdraw"
  }, {
    path: "/center/myself/identityAuthentication",
    component: _b2527e8c,
    name: "center-myself-identityAuthentication"
  }, {
    path: "/center/myself/myselfInfo",
    component: _43bca3aa,
    name: "center-myself-myselfInfo"
  }, {
    path: "/center/myself/recharge",
    component: _908aadea,
    name: "center-myself-recharge"
  }, {
    path: "/center/myself/thirdPartyBinding",
    component: _31a26592,
    name: "center-myself-thirdPartyBinding"
  }, {
    path: "/center/sell/sellBargainGoods",
    component: _2edb1cd8,
    name: "center-sell-sellBargainGoods"
  }, {
    path: "/center/sell/sellOrder",
    component: _c3622d64,
    name: "center-sell-sellOrder"
  }, {
    path: "/center/sell/sellOutGoods",
    component: _3c26f7d8,
    name: "center-sell-sellOutGoods"
  }, {
    path: "/center/sell/Sellreceive",
    component: _07c71863,
    name: "center-sell-Sellreceive"
  }, {
    path: "/center/sell/sellSuccess",
    component: _4f3574fa,
    name: "center-sell-sellSuccess"
  }, {
    path: "/center/sell/sellTab",
    component: _486a2015,
    name: "center-sell-sellTab"
  }, {
    path: "/center/myself/accountSecurity/changePassword",
    component: _7b498b78,
    name: "center-myself-accountSecurity-changePassword"
  }, {
    path: "/center/myself/accountSecurity/changePhone",
    component: _3cbf2fd6,
    name: "center-myself-accountSecurity-changePhone"
  }, {
    path: "/",
    component: _1c028c0b,
    name: "index"
  }, {
    path: "/black/:sn",
    component: _05778ef2,
    name: "black-sn"
  }, {
    path: "/detail/:sn?",
    component: _bcdc867c,
    name: "detail-sn"
  }, {
    path: "/JDetail/:sn?",
    component: _f61f4d14,
    name: "JDetail-sn"
  }, {
    path: "/news/:code",
    component: _6e382812,
    name: "news-code"
  }, {
    path: "/goods/:sn?/order",
    component: _2acaa9ba,
    name: "goods-sn-order"
  }, {
    path: "/goods/:sn?/pay",
    component: _34a182bd,
    name: "goods-sn-pay"
  }, {
    path: "/goods/:sn?/success",
    component: _40c7e618,
    name: "goods-sn-success"
  }, {
    path: "/wares/:sn/order",
    component: _36e15163,
    name: "wares-sn-order"
  }, {
    path: "/wares/:sn/pay",
    component: _22dcb8fd,
    name: "wares-sn-pay"
  }, {
    path: "/wares/:sn/success",
    component: _d4040750,
    name: "wares-sn-success"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
