import CryptoJS from "crypto-js";
import store from '@/store';
const storeInstance = store();
export default {
  // 加密
  encrypt(word, keyStr, ivStr) {
    keyStr = keyStr ? keyStr : "9871267812345mn812345xyz";
    ivStr = ivStr ? ivStr : "9871267812345mn812345xyz";

    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let iv = CryptoJS.enc.Utf8.parse(ivStr);
    let srcs = CryptoJS.enc.Utf8.parse(word);

    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
    });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext).substring(0,CryptoJS.enc.Base64.stringify(encrypted.ciphertext).length-4);
  },
  // 解密
  decrypt(word, keyStr, ivStr) {
    keyStr = keyStr ? keyStr : "9871267812345mn812345xyz";
    ivStr = ivStr ? ivStr : "9871267812345mn812345xyz";
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    let iv = CryptoJS.enc.Utf8.parse(ivStr);

    var decrypt = CryptoJS.AES.decrypt(word, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
  },
  decryptAES(ciphertextBase64) {
    let key = storeInstance.state.web.a
    let decrypt = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Base64.parse(ciphertextBase64) }, CryptoJS.enc.Utf8.parse(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    let data = decrypt.toString(CryptoJS.enc.Utf8)
    return  JSON.parse(data)
  },
  encryptAES(plaintext) {
    let key = storeInstance.state.web.a
    plaintext = JSON.stringify(plaintext)
    let encrypt = CryptoJS.AES.encrypt(plaintext, CryptoJS.enc.Utf8.parse(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypt.toString()
  },
};
