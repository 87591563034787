
import { modifyStatus } from "~/api/user";
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
@Component({
  name: "centerMemberInfo2",
})
export default class centerMemberInfo2 extends Vue {
  @Prop(Object) userInfo_?: any
  @Prop({ default: () => { } }) favList_?: any
  public mobileRegular: any //手机号正则表达式
  private progress: number = 30
  public logout: any
  public getCookie: any
  private progressName: string = '一般'
  private switchValue: boolean = true
  mounted() {
    console.log('userInfo_userInfo_userInfo_userInfo_', this.userInfo_, this.favList_, 'userInfo_userInfo_userInfo_userInfo_');

    if (this.userInfo_.authStatus == 2) {
      this.progress += 10
    }
    if (this.userInfo_.isHadPhone == 1) {
      this.progress += 10
    }
    if (this.userInfo_.isHadPassWord == 1) {
      this.progress += 10
    }
    if (this.userInfo_.isPayPassWord == 1) {
      this.progress += 10
    }
    if (this.userInfo_.isHadEmail == 1) {
      this.progress += 10
    }
    if (this.userInfo_.aliAccount || this.userInfo_.wxAccount || this.userInfo_.bankAccount) {
      this.progress += 10
    }
    this.progress = this.progress / 10
    console.log(this.progress)
    if (this.progress >= 9) {
      this.progressName = '安全'
    } else if (this.progress >= 6) {
      this.progressName = '良好'
    } else if (this.progress >= 3) {
      this.progressName = '一般'
    } else {
      this.progressName = '危险'
    }
  }
  async handleLogOffAccount() {

    this.$confirm('是否确认注销账户?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      let userId = this.getCookie("userId");
      const params = {
        userId,
        status: 2,
      }
      let res: any = await modifyStatus({ method: 'POST', data: params })
      console.log('res',res);
      
      if (res.data.code == 0) {
        this.logout()
      }
    }).catch(() => {
      return
    });

 
  }
  // get promoteCoin() {

  // }
}
